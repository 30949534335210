<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Roll Sheets</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col sm="3">
                                <b-form-group label-for="session-date-range" label="Session Start Date Within:">
                                    <picker-of-dates type="date"
                                                     id="session-date-range"
                                                     v-model="dateRange"
                                                     format="MM/dd/yyyy"
                                                     placeholder="Select Date Range"
                                                     clearable range />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm>
                                <b-form-group label-for="search-workshops" label="Workshop(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-workshops"
                                                  v-model="workshop"
                                                  :options="workshopOptions"
                                                  :select-size="8" />
                                        <b-input-group-addon>
                                            <b-button @click="workshop = null">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col sm>
                                <b-form-group label-for="search-sessions" label="Session(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-sessions"
                                                  v-model="sessionId"
                                                  :options="sessionOptions"
                                                  :select-size="8" />
                                        <b-input-group-addon>
                                            <b-button @click="sessionId = null">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2"
                                      variant="success"
                                      @click="generateCsv">Export</b-button>
                            <b-button class="mr-2"
                                      variant="dark"
                                      @click="generatePdf">Generate PDF</b-button>
                            <b-button variant="info"
                                      @click="clear">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import {Component, Vue, Watch} from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import _ from "underscore";
import PickerOfDates from '@/components/shared/PickerOfDates';
import {errorModalOptions, errorToastOptions, mkDate} from "../../../util/formatters";
import {isAfter, isBefore, isValid, parse} from 'date-fns';

@Component({
    components: {
        PickerOfDates
    }
})
export default class RollSheets extends Vue {
    dateRange = [];
    workshop = null;
    sessionId = null;

    clear() {
        this.dateRange = [];
        this.workshop = null;
        this.sessionId = null;
    }

    get readyToGenerate() {
        if (_.isNull(this.sessionId)) {
            this.$bvModal.msgBoxOk('Please select a session to proceed.', {
                size: 'sm',
                buttonSize: 'sm'
            });
            return false;
        }
        return true;
    }

    get isMentorWorkshop() {
        // console.log(this.workshop);
        if (_.isNull(this.workshop)) {
            // console.log("<<< HERE >>>");
            return false;
        }
        // console.log(this.workshop.isMentorSession);
        return this.workshop.isMentorSession;
    }

    get parameters() {
        const params = {
            sessionId: this.sessionId,
            mentorSession: this.isMentorWorkshop
        };
        return params;
    }

    async generateCsv() {
        if (!this.readyToGenerate) {
            return;
        }
        try {
            await download('POST', '/api/v1/export/roll-sheet', JSON.stringify(this.parameters));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async generatePdf() {
        if (!this.readyToGenerate) {
            return;
        }
        try {
            await download('POST', '/api/v1/report/roll-sheet', JSON.stringify(this.parameters));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    get workshopOptions() {
        return _.chain(this.$store.getters['common/programWorkshops'])
            .map((pw) => {
                return {
                    value: pw,
                    text: pw.workshopName
                }
            })
            .value();
    }

    get sessionOptions() {
        const sessions = this.$store.getters['common/workshopSessionsSessionID'];
        const options = _.chain(sessions)
            .filter(s => {
                //If there are no date filters, proceed
                if (_.isEmpty(this.dateRange) || (!_.isDate(this.dateRange[0]) && !_.isDate(this.dateRange[1]))) {
                    return true;
                }
                //Extract session dates from label
                const dateRegEx = /(\d{1,2}\/\d{1,2}\/\d{4})/g;
                const sessionDates = _.map(s.value.match(dateRegEx) || [], d => {
                    //mkDate doesn't accommodate M/d/yyyy format
                    const date = parse(d, 'M/d/yyyy', new Date());
                    return isValid(date) ? date : null;
                });
                //If there are not valid start and end dates, exclude session
                if (_.isEmpty(sessionDates) || sessionDates.length !== 2 || !_.isDate(sessionDates[0]) ||
                    !_.isDate(sessionDates[1])) {
                    return false;
                }
                //Compare dates
                if (_.isDate(this.dateRange[0]) && _.isDate(this.dateRange[1])) {
                    return isBefore(this.dateRange[0], sessionDates[0]) && isAfter(this.dateRange[1], sessionDates[1]);
                }
                else if (_.isDate(this.dateRange[0])) {
                    console.log('WARNING: no session end date filter selection');
                    return isBefore(this.dateRange[0], sessionDates[0]);
                }
                else {
                    console.log('WARNING: no session start date filter selection');
                    return isAfter(this.dateRange[1], sessionDates[1]);
                }
            })
            .map(s => {
                return {
                    text: s.value,
                    value: s.id
                };
            })
            .value();
        return options;
    }

    @Watch('workshop')
    async workshopsSelected(workshop) {
        this.sessionId = null;
        const workshopId = _.isEmpty(workshop) ? [] : Array.of(workshop.workshopId);
        await this.$store.dispatch('common/loadWorkshopSessionsSessionID', workshopId)
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
    }

    destroyed() {
        //Clear any prior workshop sessions
        this.$store.dispatch('common/loadWorkshopSessionsSessionID', [])
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Roll Sheets', null, true));
        //Clear any prior workshop sessions
        this.$store.dispatch('common/loadWorkshopSessionsSessionID', [])
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
        _.each([
            'common/loadProgramWorkshops'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
    }
}
</script>

<style scoped>

</style>